import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  private environment = environment;

  constructor(
  ) {}

  ngOnInit(): void {
    
    if (this.environment['buildNumber']) {
      console.log('build number', this.environment['buildNumber']);
    }

  }

}
