import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private featuresLsKey: string = 'features';
  private userKey: string = 'currentUser';

  /**
   * Get user from localStorage
   */
  getUser(): any {
    const user: any = localStorage.getItem(this.userKey);
    return user ? JSON.parse(user) : null;
  }

  /**
   * Set user in localStorage
   */
  setUser(user: any): void {
    localStorage.setItem(this.userKey, JSON.stringify(user));
  }

  /**
   * Check if user is a SSL developer
   * @returns 
   */
  get isDeveloper(): boolean {

    const user = this.getUser();
    let isDeveloper: boolean = false;

    if (/@sleepscorelabs.com\s*$/.test(user.Email)) {
      isDeveloper = true;
    }

    return isDeveloper;

  }

  /**
   * Get user roles
   */
  get userRoles(): string[] {

    let roles: string[] = [];

    const user = this.getUser();
    if (user) {

      roles = user.roles.split(',');

      if (this.isDeveloper) {
        roles.push('dev');
      }

    }

    return roles;

  }

  /**
   * Is user a CSR
   * @returns 
   */
  get isCSR(): boolean {
    return this.userRoles.includes('csr');
  }

  /**
   * Is user a sleepcoach
   * @returns 
   */
  get isSleepCoach(): boolean {
    return this.userRoles.includes('sleepcoach');
  }

  /**
   * Get logged in user's features 
   * @returns 
   */
  get features(): string[] {
    let features: string | null = localStorage.getItem(this.featuresLsKey);
    return features ? JSON.parse(features) : [];
  }

  /**
   * Set user's features
   * @param features 
   */
  setFeatures(features: string[]): void {
    localStorage.setItem(this.featuresLsKey, JSON.stringify(features));
  }

  /**
   * Check if user has access to feature
   * @param feature 
   * @returns boolean
   */
  hasFeature(feature: string): boolean {
    return this.features.includes(feature);
  }

}
