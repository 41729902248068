import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/guards/auth.guard';
import { CanActivatePartnerGuard } from './shared/guards/can-activate-partner.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { 
    path: 'login',  
    loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule),
    title: 'Login'
  }, { 
    path: 'loginregistration',  
    loadChildren: () => import('./components/login-registration/login-registration.module').then(m => m.LoginRegistrationModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Registration',
    data: {partners: ['default', 'RMDG']}
  }, {
    path: 'providerregistration',
    loadChildren: () => import('./components/provider-registration/provider-registration.module').then(m => m.ProviderRegistrationModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Provider Registration',
    data: {partners: ['default', 'RMDG']}
  }, { 
    path: 'registration',  
    children: [{
      path: '',
      loadChildren: () => import('./components/registration/registration.module').then(m => m.RegistrationModule),
      canActivate: [CanActivatePartnerGuard],
      title: 'Registration',
      data: {partners: ['default', 'RMDG']},
    }, {
      path: 'email',
      loadChildren: () => import('./components/registration/registration-email/registration-email.module').then(m => m.RegistrationEmailModule),
      canActivate: [CanActivatePartnerGuard],
      title: 'Signup with Email',
      data: {partners: ['default', 'RMDG']},
    }]
  }, { 
    path: 'completeregistration', 
    loadChildren: () => import('./components/complete-registration/complete-registration.module').then(m => m.CompleteRegistrationModule),
    canActivate: [AuthGuard],
    title: 'Complete Registration'
   }, {
    path: 'authcompleteregistration',
    loadChildren: () => import('./components/auth-complete-registration/auth-complete-registration.module').then(m => m.AuthCompleteRegistrationModule),
    title: 'Auth Complete Registration'
  }, { 
    path: 'authcomplete',  
    loadChildren: () => import('./components/auth-complete/auth-complete.module').then(m => m.AuthCompleteModule),
    title: 'Auth Complete'
  }, { 
    path: 'download',  
    loadChildren: () => import('./components/download/download.module').then(m => m.DownloadModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Download',
    data: {partners: ['default', 'RMDG']}
  }, { 
    path: 'providerlogin',  
    loadChildren: () => import('./components/providerlogin/providerlogin.module').then(m => m.ProviderloginModule),
    title: 'Provider Login',
  }, {
    path: 'unsubscribe',  
    loadChildren: () => import('./components/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Unsubscribe',
    data: {partners: ['default']}
  }, {
    path: 'purchase', 
    loadChildren: () => import('./components/purchase/purchase.module').then(m => m.PurchaseModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Purchase',
    data: {partners: ['default', 'RMDG']}
  }, { 
    path: 'activationcodeverification',  
    loadChildren: () => import('./components/activation-code-verification/activation-code-verification.module').then(m => m.ActivationCodeVerificationModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Activation Code Verification',
    data: {partners: ['default', 'RMDG']}
  }, { 
    path: 'dashboard',  
    loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  }, { 
    path: 'login/forgotpassword',  
    loadChildren: () => import('./components/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Forgot Password',
    data: {partners: ['default', 'RMDG']}
  }, { 
    path: 'account/changeforgottenpassword/:type/:id',  
    loadChildren: () => import('./components/change-forgotton-password/change-forgotton-password.module').then(m => m.ChangeForgottonPasswordModule),
    canActivate: [CanActivatePartnerGuard],
    title: 'Change Forgotten Password',
    data: {partners: ['default', 'RMDG']}
  }, {
    path: 'validationstudy',
    children: [{
      path: '',
      loadChildren: () => import('./components/validation-study/validation-study-activation-verification/validation-study-activation-verification.module').then(m => m.ValidationStudyActivationVerificationModule),
      title: 'Validation Study'
    }, {
      path: 'registration',
      loadChildren: () => import('./components/validation-study/validation-study-registration/validation-study-registration.module').then(m => m.ValidationStudyRegistrationModule),
      title: 'Validation Study Registration'
    }, {
      path: 'login',
      loadChildren: () => import('./components/validation-study/validation-study-login/validation-study-login.module').then(m => m.ValidationStudyLoginModule),
      title: 'Validation Study Login'
    }, {
      path: 'participantverification',
      loadChildren: () => import('./components/validation-study/validation-study-participant-verification/validation-study-participant-verification.module').then(m => m.ValidationStudyParticipantVerificationModule),
      title: 'Validation Study Participant Verficiation'
    }, {
      path: 'download',
      loadChildren: () => import('./components/validation-study/validation-study-download/validation-study-download.module').then(m => m.ValidationStudyDownloadModule),
      title: 'Validation Study Download App'
    }]
  }, {
    path: 'questionnaire',
    children: [{
      path: 'clinicalscreener',
      loadChildren: () => import('./components/questionnaire/clinical-screener/clinical-screener.module').then(m => m.ClinicalScreenerModule),
      title: 'Clinical Screener'
    }, {
      path: 'standalonescreener',
      loadChildren: () => import('./components/questionnaire/standalone-screener/standalone-screener.module').then(m => m.StandaloneScreenerModule),
      title: 'Standalone Screener'
    }]
  }, {
    path: 'sleep-experiments',
    loadChildren: () => import('./components/sleep-experiments/sleep-experiments.module').then(m => m.SleepExperimentModule),
    canActivate: [CanActivatePartnerGuard],
    data: {partners: ['default', 'MFRM']}
  }, {
    path: 'myndstream',
    loadChildren: () => import('./components/myndstream/myndstream-medley/myndstream-medley.module').then(m => m.MyndstreamMedleyModule),
    title: 'Myndstream Medleys'
  }, { 
    path: 'webviewtransition', 
    loadChildren: () => import('./components/webviewtransition/webviewtransition.module').then(m => m.WebviewtransitionModule)
  }, {
    path: '**',
    loadChildren: () => import('./components/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    title: 'Not Found'
  }
];

@Injectable({providedIn: 'root'})
export class TemplatePageTitleStrategy extends TitleStrategy {

  private environment = environment;

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {

    const title = this.buildTitle(routerState);

    if (title !== undefined) {
      this.title.setTitle(`${title} | ${this.environment.siteTitle}`);
    }

  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},
  ]
})
export class AppRoutingModule { }
