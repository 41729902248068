import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService, MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { MultiTranslationLoader } from './shared/classes/multi-translation-loader';
import { environment } from 'src/environments/environment';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';


class PortalMissingTranslationHandler implements MissingTranslationHandler {

  private environment = environment;

  handle(params: MissingTranslationHandlerParams) {

    if (!this.environment.production) {
      console.error('Missing translation: ', params.key);
    }
    return '';
  
  }

}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({                  
      loader: {
        provide: TranslateLoader,
        useClass: MultiTranslationLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: PortalMissingTranslationHandler}
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }, {
      provide: MAT_DATE_LOCALE,
      useValue: navigator.language
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(
    private translate: TranslateService
  ) {

    // supported languages
    const languages: string[] = ['en', 'de'];

    // get language set by the browser 
    const browserLang: string = navigator.language;

    // check if browser language matches a supported language
    const language: string | undefined = languages.find((lang: string) => {
      const regExp = new RegExp(`^${lang}`);
      return regExp.test(browserLang);
    });

    // default the language to en
    let useLanguage: string = 'en';
    if (language) {
      useLanguage = language
    }

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(useLanguage);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(useLanguage);

  }

}
